import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/Layouts/Layout.jsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const BlogCard = makeShortcode("BlogCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <BlogCard mdxType="BlogCard">
      <h1>{props.pageContext.frontmatter.title}</h1>
      <p>{`I'm currently working on a project around accessibility when using React and as part of that I took a look at `}<a parentName="p" {...{
          "href": "https://reach.tech/"
        }}>{`Reach UI's`}</a>{` accordion implementation, which is interesting in how it handles the tracking of releationships between the various accordion sections. I dug into that a bit more because it's always good to see different approaches, especially from people who have years more experience than I do and in the context of a component library that anticipates the issues that come along with reuse in different contexts.`}</p>
      <p>{`Taking the Reach UI code as a starting point, I've extracted a `}<a parentName="p" {...{
          "href": "https://codesandbox.io/s/context-accordion-83qv5"
        }}>{`minimal example`}</a>{` that shows how it works, albeit in a much less robust way and no doubt more buggy way than the actual `}<a parentName="p" {...{
          "href": "https://github.com/reach/reach-ui/blob/develop/packages/accordion/src/index.tsx"
        }}>{`Reach implementation`}</a>{`.`}</p>
      <h2>{`What problem does this approach solve?`}</h2>
      <p><a parentName="p" {...{
          "href": "https://github.com/reach/reach-ui/blob/develop/packages/descendants/README.md"
        }}>{`This readme`}</a>{` explains the issue but I'll summarise it here.`}</p>
      <p>{`When we have something like an accordion, we need to know the relationships between the various elements that make up the component so that we know which element is active and also so we can handle the navigation between the elements. `}<a parentName="p" {...{
          "href": "https://jorigg.dev/blogPosts/component-library-accordion/"
        }}>{`I created an Accordion implementation`}</a>{` where this was achieved by the user passing an array of items into the top level component and that was then used to create the children (the button and panel sections) and track their relationships (using their order in the array). However, this leaves no way for that user to pass props to the child components.`}</p>
      <p>{`If you had built the component as part of your own app, this might not be much of a problem: you'd just add another prop to Accordion and pass it down (although even here, you could end up with a profusion of props, some of which are only needed in one particular usage of the Accordion).`}</p>
      <p>{`However, if you were providing the Accordion as a package or as part of a library you'd leave your users with no way of, for example, assigning a CSS class name to the child elements of the Accordion.`}</p>
      <p>{`This alternative approach lets the user compose their accordion using the child components directly, so they can assign a className to a button (for example). Instead of creating the Accordion like this...`}</p>
      <pre><code parentName="pre" {...{
          "className": "language-jsx"
        }}>{`<Accordion items={arrayOfItems} />
`}</code></pre>
      <p>{`...you'd instead create it like the example below:`}</p>
      <pre><code parentName="pre" {...{
          "className": "language-jsx"
        }}>{`<AccordionCtx>
  <AccordionItem>
    <h3>
      <AccordionButton>You can activate me</AccordionButton>
    </h3>
    <AccordionPanel className={styles.blue}>
      Ante rhoncus facilisis iaculis nostra faucibus vehicula ac consectetur
      pretium, lacus nunc consequat id viverra facilisi ligula eleifend, congue
      gravida malesuada proin scelerisque luctus est convallis.
    </AccordionPanel>
  </AccordionItem>
  <AccordionItem>
    <h3>
      <AccordionButton>and me</AccordionButton>
    </h3>
    <AccordionPanel>
      Ante rhoncus facilisis iaculis nostra faucibus vehicula ac consectetur
      pretium, lacus nunc consequat id viverra facilisi ligula eleifend, congue
      gravida malesuada proin scelerisque luctus est convallis.
    </AccordionPanel>
  </AccordionItem>
</AccordionCtx>
`}</code></pre>
      <p>{`Great eh? I can now pass some specific styles into one or more elements of the accordion. But, in the absence of that array giving us the order of elements, how on earth then can we implement the navigation/active element functionality? React context comes to our rescue here.`}</p>
      <h2>{`What is React context?`}</h2>
      <p>{`React context can be used to share some state between several components without needing to pass it around as props. It's main use is to avoid 'prop drilling', where a prop needs to be passed down through several components, but it can also be used to have shared state amongst a smaller, localised set of components.`}</p>
      <p><a parentName="p" {...{
          "href": "https://kentcdodds.com/blog/how-to-use-react-context-effectively"
        }}>{`Kent Dodds has a good blog post on using context`}</a>{` if you're new to it.`}</p>
      <h2>{`Tracking descendent components using context`}</h2>
      <p>{`In the `}<a parentName="p" {...{
          "href": "https://codesandbox.io/s/context-accordion-83qv5"
        }}>{`example I created`}</a>{` the parent Accordion component (AccordionCtx) wraps its children in two context providers: one for the descendants and the other for other information about the accordion state, including the setter to store the index of the open panel. There's a further piece of context wrapping each button/panel pair in the AccordionItem (holding the ref for the item and its current status).`}</p>
      <p>{`Its the descendants we're interested in here though.`}</p>
      <p>{`Each AccordionItem calls the useDescendant hook, passing its ref as a parameter. This hook is responsible for registering the component to the descendants array that is held in the descendants context. The hook returns the component's index and that is stored in the item's own context (along with the item's own ref).`}</p>
      <p>{`We now have both an array of child items (the descendants array) and each item knows its own index within that array.`}</p>
      <p>{`This can then be used in the following way in the AccordionButton component:`}</p>
      <ul>
        <li parentName="ul">{`The ref for the item is assigned to the button`}</li>
        <li parentName="ul">{`The button's click handler can use the ref to give the clicked item focus and call the callback to set the index of the open panel that was passed in from the top level component, passing in it's index to set it as the active panel`}</li>
        <li parentName="ul">{`The descendants array can be used in the handler for the keyboard navigation, using the current item's index as a baseline to move through the array (`}<a parentName="li" {...{
            "href": "https://jorigg.dev/blogPosts/component-library-accordion/"
          }}>{`in the same way I did in my previous example`}</a>{`).`}</li>
      </ul>
      <h2>{`So should I rewrite my accordion to work like this?`}</h2>
      <p>{`Maybe not! This was an interesting exercise (especially in going through some library code to work out how it works) but the problem this solves is more applicable to libraries than to the app development work I do. Sure, it can (very occasionally) get messy with optional props, but there are also pitfalls to managing it using context, you can see this by looking at how much more complex the code in Reach is and how many more scenarios it can handle. It never hurts to have another tool in the toolbox though.`}</p>
    </BlogCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      